<template>
  <div class="flex flex-wrap">
    <div class="w-full md:w-1/2 xl:w-1/3 p-3">
      <!--Metric Card-->
      <div class="bg-white border rounded shadow p-2">
        <div class="flex flex-row items-center">
          <div class="flex-shrink pr-4">
            <div class="rounded p-3 bg-green-600">
              <i class="fa fa-wallet fa-2x fa-fw fa-inverse"></i>
            </div>
          </div>
          <div class="flex-1 text-right md:text-center">
            <h5 class="font-bold uppercase text-gray-500">
              Anfragen Gesamtwert
            </h5>
            <h3 class="font-bold text-3xl">
              3249 €
              <span class="text-green-500"
                ><i class="fas fa-caret-up"></i
              ></span>
            </h3>
          </div>
        </div>
      </div>
      <!--/Metric Card-->
    </div>
    <div class="w-full md:w-1/2 xl:w-1/3 p-3">
      <!--Metric Card-->
      <div class="bg-white border rounded shadow p-2">
        <div class="flex flex-row items-center">
          <div class="flex-shrink pr-4">
            <div class="rounded p-3 bg-pink-600">
              <i class="fas fa-users fa-2x fa-fw fa-inverse"></i>
            </div>
          </div>
          <div class="flex-1 text-right md:text-center">
            <h5 class="font-bold uppercase text-gray-500">Anzahl der Kunden</h5>
            <h3 class="font-bold text-3xl">
              249
              <span class="text-pink-500"
                ><i class="fas fa-exchange-alt"></i
              ></span>
            </h3>
          </div>
        </div>
      </div>
      <!--/Metric Card-->
    </div>
    <div class="w-full md:w-1/2 xl:w-1/3 p-3">
      <!--Metric Card-->
      <div class="bg-white border rounded shadow p-2">
        <div class="flex flex-row items-center">
          <div class="flex-shrink pr-4">
            <div class="rounded p-3 bg-yellow-600">
              <i class="fas fa-user-plus fa-2x fa-fw fa-inverse"></i>
            </div>
          </div>
          <div class="flex-1 text-right md:text-center">
            <h5 class="font-bold uppercase text-gray-500">
              Neue Kunden im letzten Monat
            </h5>
            <h3 class="font-bold text-3xl">
              2
              <span class="text-yellow-600"
                ><i class="fas fa-caret-up"></i
              ></span>
            </h3>
          </div>
        </div>
      </div>
      <!--/Metric Card-->
    </div>
    <div class="w-full md:w-1/2 xl:w-1/3 p-3">
      <!--Metric Card-->
      <div class="bg-white border rounded shadow p-2">
        <div class="flex flex-row items-center">
          <div class="flex-shrink pr-4">
            <div class="rounded p-3 bg-blue-600">
              <i class="fas fa-server fa-2x fa-fw fa-inverse"></i>
            </div>
          </div>
          <div class="flex-1 text-right md:text-center">
            <h5 class="font-bold uppercase text-gray-500">
              Anfragen diese Woche
            </h5>
            <h3 class="font-bold text-3xl">152</h3>
          </div>
        </div>
      </div>
      <!--/Metric Card-->
    </div>
    <div class="w-full md:w-1/2 xl:w-1/3 p-3">
      <!--Metric Card-->
      <div class="bg-white border rounded shadow p-2">
        <div class="flex flex-row items-center">
          <div class="flex-shrink pr-4">
            <div class="rounded p-3 bg-indigo-600">
              <i class="fas fa-tasks fa-2x fa-fw fa-inverse"></i>
            </div>
          </div>
          <div class="flex-1 text-right md:text-center">
            <h5 class="font-bold uppercase text-gray-500">
              Durchschnittliche Antwortzeit
            </h5>
            <h3 class="font-bold text-3xl">3 Stunden</h3>
          </div>
        </div>
      </div>
      <!--/Metric Card-->
    </div>
    <div class="w-full md:w-1/2 xl:w-1/3 p-3">
      <!--Metric Card-->
      <div class="bg-white border rounded shadow p-2">
        <div class="flex flex-row items-center">
          <div class="flex-shrink pr-4">
            <div class="rounded p-3 bg-red-600">
              <i class="fas fa-inbox fa-2x fa-fw fa-inverse"></i>
            </div>
          </div>
          <div class="flex-1 text-right md:text-center">
            <h5 class="font-bold uppercase text-gray-500">
              Nicht beantwortete Anfragen
            </h5>
            <h3 class="font-bold text-3xl">
              3
              <span class="text-red-500"><i class="fas fa-caret-up"></i></span>
            </h3>
          </div>
        </div>
      </div>
      <!--/Metric Card-->
    </div>
  </div>
</template>

<script>
export default {
  name: "Metrics"
};
</script>

<style scoped></style>
