<template>
  <metrics />
</template>

<script>
import Metrics from "@/components/Metrics";

export default {
  name: "Dashboard",
  components: {
    Metrics
  }
};
</script>

<style scoped></style>
